<template>
  <div class="top5Chart">
    <ul class="contentTitle">
      <li v-for="(item, index) in title" :key="index">
        <p :class="type == 'user_name' ? 'name userNameTitle' : 'name'">{{item.firstTitle}}</p>
        <p class="date">{{item.secondTitle}}</p>
        <p class="times">{{item.times}}</p>
      </li>
<!--      <p v-if="dataList.length == 0" class="no-data" style="text-align: center;line-height: 100px">暂无数据</p>-->
    </ul>
    <ul class="content">
      <li v-for="(item, index) in mediaData" :key="index" >
        <p :class="type == 'user_name' ? 'name userName' : 'name'">{{item.name}}</p>
        <p class="date">{{item.date}}</p>
        <el-progress class="bar" color="#2D2FAD" :percentage="Number((item.count/maxDocCount*100).toFixed(2))" :show-text="isShowText" />
        <p class="number"><span>{{item.count}}</span></p>
      </li>
      <p v-if="dataList.length == 0" class="no-data" style="text-align: center;line-height: 100px">暂无数据</p>
    </ul>
  </div>
</template>

<script>


  import * as echarts from "echarts";

  export default {
    name: "top5Chart",
    components:{
    },
    props:{
      type:String,
      active:String,
      isShow:Boolean,
      dataList:Array,
      mediaTypeList:Array,
      isMediaActive:Number,
      title:Array,
    },
    data(){
      return{
        isShowText:false,
        showDataChangeDialog: false,
        maxDocCount:this.getMaxDoc(),
        mediaData:[],
        activeMedia:0,
        newMediaTypeList:[],
        screenWidth: '',
        screenHeight: ''
      }
    },
    mounted(){
      // this.screenWidth = document.body.clientWidth;
      // this.screenHeight = document.body.clientHeight;
      // window.onresize = () => {
      //   this.$nextTick(() => {
      //     const node = this.$refs.strip
      //     if(node){
      //       echarts.init(node).resize()
      //     }
      //   })
      // };
    },
    created() {
      console.log(this.type)
      this.mediaData = this.dataList
      console.log('this.mediaData',this.mediaData)
    },
    watch:{
      dataList(newValue,oldValue){
        // console.log('newValue',newValue)
          this.mediaData = newValue
        this.maxDocCount = this.getMaxDoc()

      }
    },
    methods: {
      openDataFun(columns,data)  {
        this.showDataChangeDialog = true;
        this.$refs.dataChangeDialog.getMessage(columns,data);
      },
      getMaxDoc(){
        let docArr = []
        if(this.dataList.length!=0){
          this.dataList.map(m => {
            docArr.push(m.count)
          })
        }
        return Math.max(...docArr)

      }


    }
  }
</script>


<style lang="less">
  .top5Chart{
    >div{
      text-align: center;
      font-weight: 700;
      font-size: 18px;
      /*margin-bottom: 10px;*/
    }
  }
  .top5Chart .tool-box {

    overflow: hidden;
  > ul {
    box-sizing: border-box;
    float: left;
    width: calc(100% - 50px);
  li {
    height: 30px;
    padding: 0 15px;
    border: 1px solid #ddd;
    float: left;
    line-height: 28px;
    margin: -1px 0 0 -1px;
  &.active {
     position: relative;
     z-index: 1;
     border: 1px solid #12d4c9;
     color: #fff;
   // background: #12d4c9;
   }
  }
  }
  > i {
    float: right;
    line-height: 30px;
    font-size: 20px;
    color: #666

  }
  }
  .top5Chart{
    width: 100%;
    background-color: white;
    padding: 10px 0;
    >.contentTitle{
      li>{
        font-weight: 700;
        width: 100%;
        display: flex;
        .name{
          padding-left: 4px;
          box-sizing: border-box;
          text-align: center;
          width: 13%;
          max-width: 200px;
          min-width: 110px;
        }
        .userNameTitle{
          padding-left: 14px;
          box-sizing: border-box;
        }
        .date{
          padding-left: 4px;
          box-sizing: border-box;
          text-align: center;
          width: 13%;
          max-width: 200px;
          min-width: 100px;
        }
        .times{
          text-align: center;
          flex-grow: 1;
        }
      }
    }
    >.content{
      min-height: 180px;
      padding: 0 13px;
      justify-content: space-around;
      display: flex;
      flex-wrap: wrap;
      /*display: flex;*/
      /*flex-direction: column;*/
      /*flex-wrap: wrap;*/
      > li{
        height: 18px;
        color: black;
        width: 100%;
        display: flex;
        justify-content:space-between;
        > .name{
          margin: 0;
          width: 13%;
          max-width: 200px;
          min-width: 100px;
          display:-webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          height: 18px
        }
        >.userName{
          text-align: center;
        }
        > .date{
          text-align: center;
          margin: 0;
          width: 13%;
          max-width: 200px;
          min-width: 100px;
          display:-webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          height: 18px
        }
        >.times{
          text-align: center;
          margin: 0;
          width: 74%;
          max-width: 200px;
          min-width: 80px;
          display:-webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          height: 18px
        }
        > .bar {
          margin-top: 4px;
          /*padding-top: 8px;*/
          display: flex;
          align-items: center;
          flex-grow: 1;
          >.el-progress-bar{
            height: 12px;
            /*background-color: #3ea3f9;*/
            >.el-progress-bar__outer{
              height: 100% !important;
              background-color: white;
              border-radius: 0!important;
              >.el-progress-bar__inner{
                height: 100%;
                background-color: #3ea3f9!important;
                border-radius: 0!important;
              }
            }
          }
        }
        .number {
          margin: 0;
          text-align: center;
          width: 5%;
        }
      }
    }
  }

</style>
<style scoped>

</style>
