<template>
    <div>
        <!--    折线图-->
        <div ref="lineAreas" class="lineAreas" v-if="showChart">
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        props:{
            startTime:String,
            endTime:String,
            dataList:Array,
            title:String
        },
        components:{
            // dataChange
        },
        name: "lineAreas",
        data(){
            return {
                showChart:true,
                dataX:[],
                propagationTrends:[],
                showDataChangeDialog:false,
                screenWidth: '',
                screenHeight: ''
            }
        },
        mounted() {
            this.screenWidth = document.body.clientWidth;
            this.screenHeight = document.body.clientHeight;
            window.onresize = () => {
                this.$nextTick(() => {
                    const node = this.$refs.lineAreas
                    if(node){
                        // alert(node)
                        echarts.init(node).resize()
                    }
                })
            };
            this.propagationTrends = this.dataList
            if(this.propagationTrends.length != 0){
                this.echartsInit()
            }


        },
        methods:{
            openDataFun(columns,data)  {
                this.showDataChangeDialog = true;
                this.$refs.lineAreas.getMessage(columns,data);
            },
            echartsInit() {
                let _this = this
                let series = []
                var resX = []
                var resY = []
                var val = this.propagationTrends
                this.propagationTrends.map(m => {
                    series.push({
                        data: m.y,
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)'
                        },
                        name: '访问次数',
                        symbol:'circle',
                        type:'line',
                        // symbolSize: 4,
                        // smooth: 0.5,
                        areaStyle: {
                            normal: {
                                color: 'rgba(15,199,255,0.21)' //改变区域颜色
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#3ea3f9', //改变折线点的颜色
                                lineStyle:{
                                    color:'#3ea3f9', //改变折线颜色
                                    width:1,

                                },
                                borderWidth: 1,
                            }
                        }
                    })
                })
                var startTime = this.startTime
                var endTime = this.endTime
                //因为初始化echarts 的时候，需要指定的容器 id='main'
                echarts.init(this.$refs.lineAreas).setOption({
                    title:{
                        top:10,
                        show:true,
                        text:this.title,
                        x:'center',
                        textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                            // fontFamily: 'Arial, Verdana, sans...',
                            fontSize: 18,
                            fontStyle: 'normal',
                            fontWeight: '600',
                        },
                    },
                    xAxis:  {

                        type: 'category',
                        boundaryGap:false,
                        // boundaryGap: _this.isSpreadTrend == 0 ? false : true,
                        data: this.propagationTrends[0].x,
                        // axisLine: {
                        //     lineStyle: {
                        //         color: "#666"
                        //     }
                        // },
                        // axisTick: {
                        //     lineStyle: {
                        //         color: "#12d4c9",
                        //     },
                        //     // interval: 0,
                        // },
                        axisTick:{
                            show:false//不显示坐标轴刻度线
                        },
                        axisLine: {
                            show: false,//不显示坐标轴线
                        },
                        axisLabel: {
                            // interval:0,
                            color: '#333',
                            // rotate: '-45'
                        },


                    },
                    yAxis: {
                        type: 'value',
                        // axisLine: {
                        //     lineStyle: {
                        //         color: "#666"
                        //     }
                        // },
                        // axisTick: {
                        //     show: false,
                        // },
                        splitLine: {
                            show: false,
                        },
                        axisTick:{
                            show:false//不显示坐标轴刻度线
                        },
                        axisLine: {
                            show: false,//不显示坐标轴线
                        },
                        splitNumber: 5,
                        minInterval: 1
                    },
                    series: series,
                    tooltip: {
                        trigger: 'axis',
                        textStyle:{
                            align:'left'
                        },
                        // formatter: '{a} <br/>{b} : {c}篇 ({d}%)'
                        formatter: function (params) {
                            let htmlStr = ''
                            if(startTime.split(' ')[0] == endTime.split(' ')[0]){
                                var param
                                var xName
                                var seriesName
                                var value
                                var color
                                for(var i=0;i<params.length;i++){
                                     param = params[i];
                                     xName = param.axisValue + ':00' + '-' + param.axisValue + ':59';//x轴的名称
                                     seriesName = param.seriesName;//图例名称
                                     value = param.value;//y轴值
                                     color = param.color;//图例颜色
                                    if(i===0){
                                        htmlStr += xName + '<br/>';//x轴的名称
                                    }
                                    htmlStr +='<div>';
                                    //为了保证和原来的效果一样，这里自己实现了一个点的效果
                                    htmlStr += '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:'+color+';"></span>';
                                    //圆点后面显示的文本
                                    htmlStr += seriesName + '：' + value;
                                    htmlStr += '</div>';
                                }
                            }else{
                                for(var i=0;i<params.length;i++){
                                     param = params[i];
                                     xName = param.axisValue;//x轴的名称
                                     seriesName = param.seriesName;//图例名称
                                     value = param.value;//y轴值
                                     color = param.color;//图例颜色
                                    if(i===0){
                                        htmlStr += xName + '<br/>';//x轴的名称
                                    }
                                    htmlStr +='<div>';
                                    //为了保证和原来的效果一样，这里自己实现了一个点的效果
                                    htmlStr += '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:'+color+';"></span>';
                                    //圆点后面显示的文本
                                    htmlStr += seriesName + '：' + value;
                                    htmlStr += '</div>';
                                }
                            }

                            return htmlStr;
                        },
                    },
                    grid: {
                        left: '50',
                        right: '20',
                        bottom: '28',
                        top: '14',
                    },
                    toolbox: {
                        show: true,
                        right: '15',
                        top: '-5',
                        feature: {

                        },
                    },
                })
                this.showChart = true
            }
        },
        // watch:{
        //     dataList(newValue,oldValue){
        //         console.log('newValue')
        //         console.log(newValue)
        //         this.propagationTrends = []
        //         if(newValue.length != 0){
        //             this.propagationTrends = newValue
        //             this.showChart = false
        //             this.echartsInit()
        //
        //         }
        //
        //     }
        // },
        created() {

        }
    }
</script>

<style scoped lang="less">
    .lineAreas{
        width: 100%;
        height: 223px;
        background-color: white;
      @media only screen and (min-width: 950px) {
        height: 250px;
      }
    }

</style>

